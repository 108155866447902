import About from "../comps/about";
import Ad from "../comps/ad";
import AdOne from "../comps/adone";
import Faq from "../comps/faq";
import { db } from "../comps/firebase";
import Footer from "../comps/footer";
import Hero from "../comps/hero";
import Howitworks from "../comps/howitworks";
import Markets from "../comps/markets";
import MetricsDashboard from "../comps/metricsDashboard";
import Navbar from "../comps/navbar";
import Testimony from "../comps/testi";
import { motion } from "framer-motion";

const Landing = () => {
  return (
    <>
      <Navbar />
      <motion.div
        initial={{ x: 100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: -100, opacity: 0 }}
        transition={{ type: "spring", stiffness: 80, duration: 0.2 }}
      >
        <Hero />
        <Markets />
        <About />
        <Ad />
        <Howitworks />
        <MetricsDashboard db={db} />
        <Testimony />
        <Faq />
        <AdOne />
        <Footer />
      </motion.div>
    </>
  );
};

export default Landing;
