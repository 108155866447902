import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
// import { Card } from "@/components/ui/card";
import {
  collection,
  getDocs,
  addDoc,
  orderBy,
  limit,
  query,
  where,
  doc,
  getDoc,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const MetricsDashboard = ({ db }) => {
  const [data, setData] = useState([]);
  const [baseMetrics, setBaseMetrics] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Fetch the latest base metrics from Firestore
  const fetchBaseMetrics = async () => {
    try {
      const metricsRef = collection(db, "baseMetrics");
      const q = query(metricsRef, orderBy("timestamp", "desc"), limit(1));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const latestMetrics = querySnapshot.docs[0].data();
        setBaseMetrics(latestMetrics);
        return latestMetrics;
      } else {
        const initialMetrics = {
          users: 7000000,
          revenue: 652,
          timestamp: Date.now(),
        };
        await addDoc(collection(db, "baseMetrics"), initialMetrics);
        setBaseMetrics(initialMetrics);
        return initialMetrics;
      }
    } catch (error) {
      console.error("Error fetching base metrics:", error);
      return null;
    }
  };

  // Generate and save new growth data
  const generateAndSaveGrowthData = async (base) => {
    const days = 30;
    const newData = [];
    let currentUsers = base.users;
    let currentRevenue = base.revenue;

    for (let i = 0; i < days; i++) {
      const userGrowth = 1 + (Math.random() * 0.05 - 0.01); // -1% to +5%
      const revenueGrowth = 1 + (Math.random() * 0.07 - 0.01); // -1% to +7%

      currentUsers = Math.round(currentUsers * userGrowth);
      currentRevenue = Math.round(currentRevenue * revenueGrowth);

      const dayData = {
        date: new Date(
          Date.now() - (days - i) * 24 * 60 * 60 * 1000
        ).toLocaleDateString("en-GB", {
          day: "numeric",
          month: "short",
          year: "numeric",
        }),
        users: currentUsers,
        revenue: currentRevenue,
        timestamp: Date.now() + i * 24 * 60 * 60 * 1000,
      };

      newData.push(dayData);

      try {
        await addDoc(collection(db, "dailyMetrics"), dayData);
      } catch (error) {
        console.error("Error saving daily metrics:", error);
      }
    }

    const newBaseMetrics = {
      users: currentUsers,
      revenue: currentRevenue,
      timestamp: Date.now(),
    };

    try {
      await addDoc(collection(db, "baseMetrics"), newBaseMetrics);
      setBaseMetrics(newBaseMetrics);
    } catch (error) {
      console.error("Error saving new base metrics:", error);
    }

    return newData;
  };

  // Centralized update function
  const checkAndUpdateMetrics = async () => {
    try {
      const metricsDocRef = doc(db, "admin", "metricsControl");
      const docSnap = await getDoc(metricsDocRef);
      const today = new Date().toDateString();

      if (docSnap.exists()) {
        const { lastUpdate } = docSnap.data();
        const lastUpdateDate = new Date(lastUpdate?.toMillis()).toDateString();

        if (lastUpdateDate === today) {
          console.log("Metrics are already up-to-date for today.");
          return;
        }
      }

      console.log("Updating metrics for the new day...");
      await setDoc(metricsDocRef, { lastUpdate: serverTimestamp() });

      const base = await fetchBaseMetrics();
      await generateAndSaveGrowthData(base);
    } catch (error) {
      console.error("Error during daily metrics check and update:", error);
    }
  };

  // Initialize and fetch data
  useEffect(() => {
    const initializeData = async () => {
      setIsLoading(true);
      await checkAndUpdateMetrics();
      const historicalData = await fetchHistoricalData();
      setData(historicalData);
      setIsLoading(false);
    };

    initializeData();
  }, [db]);

  const fetchHistoricalData = async () => {
    try {
      const metricsRef = collection(db, "dailyMetrics");
      const q = query(
        metricsRef,
        where("timestamp", ">=", Date.now() - 30 * 24 * 60 * 60 * 1000),
        orderBy("timestamp", "asc")
      );
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map((doc) => doc.data());
    } catch (error) {
      console.error("Error fetching historical data:", error);
      return [];
    }
  };

  const totalUsers = data.length > 0 ? data[data.length - 1].users : 0;
  const totalRevenue = data.length > 0 ? data[data.length - 1].revenue : 0;
  const avgRevenuePerUser = totalUsers
    ? (totalRevenue / totalUsers).toFixed(2)
    : 0;

  if (isLoading) {
    return (
      <div className="px-4 md:px-8 lg:px-16 w-full">
        <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8 w-full">
          {/* Skeleton for metrics cards */}
          {Array(4)
            .fill(0)
            .map((_, index) => (
              <div
                key={index}
                className="p- rounded-md"
                style={{ minHeight: "100px" }}
              >
                <Skeleton height={20} width="60%" className="mb-2" />
                <Skeleton height={30} width="80%" />
              </div>
            ))}
        </div>

        {/* Skeleton for the graph */}
        <div className="w-full">
          <Skeleton height={300} />
        </div>
      </div>
    );
  }

  return (
    <div className="w-full px-4 md:px-8 lg:px-16">
      {/* Metrics Cards */}
      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-8">
        <div className="p-4 bg-blue-600 text-white rounded-md shadow-md">
          <div className="text-xs sm:text-sm mb-1 font-Lato">Total Users</div>
          <div className="text-lg sm:text-2xl font-bold font-Open-Sans">
            {totalUsers.toLocaleString()}
          </div>
        </div>
        <div className="p-4 bg-red-600 text-white rounded-md shadow-md">
          <div className="text-xs sm:text-sm mb-1 font-Lato">Total Revenue</div>
          <div className="text-lg sm:text-2xl font-bold font-Open-Sans">
            £{totalRevenue.toLocaleString()}
          </div>
        </div>
        <div className="p-4 bg-gray-100 rounded-md shadow-md">
          <div className="text-xs sm:text-sm mb-1 font-Lato">
            Avg. Revenue/User
          </div>
          <div className="text-lg sm:text-2xl font-bold font-Open-Sans">
            £{avgRevenuePerUser}
          </div>
        </div>
        <div className="p-4 bg-gray-100 rounded-md shadow-md">
          <div className="text-xs sm:text-sm mb-1 font-Lato">Base Values</div>
          <div className="text-xs sm:text-sm font-Open-Sans">
            Users: {baseMetrics?.users.toLocaleString()}
            <br />
            Revenue: £{baseMetrics?.revenue.toLocaleString()}
          </div>
        </div>
      </div>

      {/* Graph */}
      <div className="w-full font-Open-Sans">
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={data}>
            <XAxis
              dataKey="date"
              tick={{ fontSize: 10 }}
              interval="preserveStartEnd"
            />
            <YAxis
              yAxisId="left"
              tick={{ fontSize: 10 }}
              domain={["auto", "auto"]}
            />
            <YAxis
              yAxisId="right"
              orientation="right"
              tick={{ fontSize: 10 }}
              domain={["auto", "auto"]}
            />
            <Tooltip
              contentStyle={{
                fontSize: "12px",
              }}
            />
            <Line
              yAxisId="left"
              dataKey="users"
              type="linear"
              stroke="#1a73e8"
              strokeWidth={2}
              dot={false}
              name="Users"
            />
            <Line
              yAxisId="right"
              dataKey="revenue"
              type="linear"
              stroke="#ea4335"
              strokeWidth={2}
              dot={false}
              name="Revenue"
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default MetricsDashboard;
